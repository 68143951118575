import React from "react";

export default function LiveStream() {
    return (
        <div
            style={{
                // background:'red',
                gridRow: "2/3",
                gridColumn: "1/2",
                marginLeft: "150px",
            }}
            
            className="livestream-container"
        >
            <iframe
                width="100%"
                height="80%"
                src="https://www.youtube.com/embed/fjc0gsc5STc?si=cAdsN2P-yCbucS9P"
                title="YouTube livestream"
                frameBorder={"0"}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
            ></iframe>
        </div>
    );
}
