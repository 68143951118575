import React, { useEffect, useState } from "react";
import LiveStream from "./components/LiveStream";
import Counters from "./components/Counters";
import Info from "./components/Info";
import bgImage from "./assets/bg.jpeg";
import "./fonts/impact.ttf";
// import api from './components/api';
import { api_credentials } from "./constants";
import io from "socket.io-client";
const socket = io(api_credentials.socketURL);
function App() {
    const [counters, setCounters] = useState({
        tesla: {
            visible: false,
            value: 0,
        },
        ev: {
            visible: false,
            value: 0,
        },
    });
    useEffect(() => {
        socket.on("connect", () => {
            console.log("socket connected");
        });
        socket.on("disconnect", () => {
            console.log("socket disconnected");
        });
        socket.on("connect_error", (err) =>
            console.log("Socket connection error. Retrying!", err)
        );
        socket.on("laps_update", (data) => {
            setCounters({
                tesla: {
                    visible: true,
                    value: data?.tesla,
                },
                ev: {
                    visible: data?.ev > 0,
                    value: data?.ev,
                },
            });
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(
      () => {
      console.log(socket.connected)
        const interval = setInterval(() => {
            if (!socket.connected) socket.connect();
        }, 1000);
        return () => clearInterval(interval);
    }, []);
    return (
        <div
            style={{
                display: "grid",
                background: `url(${bgImage}) fixed center/cover no-repeat`,
                height: "100vh",
                width: "100vw",
                gridTemplateColumns: "3fr 1fr",
                gridTemplateRows: "1fr 3fr",
            }}
            className="container"
        >
            <Info />
            <LiveStream />
            <Counters counters={counters} />
        </div>
    );
}

export default App;
