import React from "react";
import { motion } from "framer-motion";

export default function Info() {
    return (
        <div
            style={{
                // background: "green",
                gridRow: "1/2",
                gridColumn: "1/2",
                marginLeft: "150px",
                display: "flex",
                alignItems: "flex-end",
                justifyContent: "space-between",
                marginBottom: "30px",
            }}
            className="info-container"
        >
            <motion.p
                initial={{ opacity: 0, y: "-200px" }}
                animate={{ opacity: 1, y: "0" }}
                transition={{ duration: 1 }}
                className="big-text"
            >
                WORLD RECORD ATTEMPT
            </motion.p>
            <motion.p
                initial={{ opacity: 0, y: "-200px" }}
                animate={{ opacity: 1, y: "0" }}
                transition={{ duration: 1 }}
                className="small-text"
            >
                FUNPARK MEPPEN // 30.09.2023
            </motion.p>
        </div>
    );
}
