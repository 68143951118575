import React from "react";
import { AnimatePresence, motion } from "framer-motion";
export default function Counters(props) {
    
    return (
        
        
        <div
            style={{
                // background: "blue",
                gridRow: "2/3",
                gridColumn: "2/3",
                display: "grid",
                gridTemplateRows: "122px 122px",
            }}
            className="counters-container"
        >
            {props?.counters?.tesla?.visible && (
                <motion.div
                    initial={{ opacity: 0, width: "0%" }}
                    animate={{ opacity: 1, width: "100%" }}
                    transition={{ duration: 1 }}
                    exit={{opacity: 0, width: "0%"}}
                    className= {`transparent-black-gradient ${props?.counters?.ev?.visible?"":'single-counter'}`}
                ></motion.div>
            )}
            {props?.counters?.ev?.visible && (
                <motion.div
                    initial={ window.innerWidth > 768 ? { opacity: 0, width: "0%" }: { opacity: 0,  width: "0%", x: 200 }}
                    animate={window.innerWidth > 768 ? { opacity: 1, width: "100%" }: { opacity: 1, width: "100%", x: 0 }}
                    exit={{opacity: 0, width: "0%"}}
                    transition={{ duration: 1 }}
                    className="transparent-green-gradient"
                ></motion.div>
            )}
            {props?.counters?.tesla?.visible && (
                <AnimatePresence>
                    <motion.div
                        initial={{ opacity: 0, width: "0%" }}
                        animate={  { opacity: 1, width: "100%" }}
                        exit={{opacity: 0, width: "0%"}}
                        transition={{ duration: 1 }}
                        className= {`tesla-counter ${props?.counters?.ev?.visible?"":'single-counter'}`}
                    >
                        <motion.p
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{ duration: 1 }}
                            className="counter-name"
                        >
                            TESLA <br />
                            COUNTER
                        </motion.p>
                        <motion.p
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{ duration: 1 }}
                            className="counter-value"
                            key={props?.counters?.tesla?.value}
                        >
                            {props?.counters?.tesla?.value}
                        </motion.p>
                    </motion.div></AnimatePresence>
            )}
            {props?.counters?.ev?.visible && (
                <AnimatePresence>
                    <motion.div
                    initial={ window.innerWidth > 768 ? { opacity: 0, width: "0%" }: { opacity: 0, width: "0%", x: 200}}
                        animate={ window.innerWidth > 768 ? { opacity: 1, width: "100%" } : { opacity: 1, width: "100%", x: 0 }}
                        exit={{opacity: 0, width: "0%"}}
                        transition={{ duration: 1 }}
                        className="ev-counter"
                    >
                        <motion.p
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{ duration: 1 }}
                            className="counter-name"
                        >
                            EV <br />
                            COUNTER
                        </motion.p>
                        <motion.p
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{ duration: 1 }}
                            className="counter-value"
                            key={props?.counters?.ev?.value}
                        >
                            {props?.counters?.ev?.value +
                                props?.counters?.tesla?.value}
                        </motion.p>
                    </motion.div>
                    </AnimatePresence>
            )}
        </div>
        
    );
}
